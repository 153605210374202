const Contacts = [
  {
    restaurant: "edi-ali-hem",
    name: "Ediths - Vimpeln",
    phone: "0322-784 45",
    email: "info@edithskok.se",
    openHours: [
      {
        id: 1,
        day: "Mån-tor",
        time: "10-19",
      },
      {
        id: 2,
        day: "Fre",
        time: "07-19",
      },
      {
        id: 3,
        day: "Lör-sön",
        time: "10-17",
      },
    ],
    lunchOpenHoursWeekdays: "11-17",
    lunchOpenHoursWeekends: "11-17",
    lunchPrice: 125,
    lunchCouponPrice: 1090,
    adress: "Hemvägen 19",
    zip: "441 39",
    city: "Alingsås",
    googleMapsUrl: "https://goo.gl/maps/u6ciNczfg8C2",
    feature: {
      catering: true,
      breakfast: true,
    },
    cateringContact: [
      {
        department: "Kallköket",
        phone: "0322-784 49",
      },
      {
        department: "Varmköket",
        phone: "0322-784 47",
      },
      {
        department: "Bageriet",
        phone: "0322-784 09",
      },
    ],
    foodboxesContacts: [{ "name": "Rasmus Packendorff", "phone": "0704081352" }, { "name": "Jonas Thunberg", "phone": "0708933006" }]
  },
  {
    restaurant: "edi-ali-sod",
    name: "Ediths - Lasarettet",
    phone: "0322-784 99",
    email: "marianne@edithskok.se",
    openHours: [
      {
        id: 1,
        day: "Mån-fre",
        time: "08-16",
      },
    ],
    lunchOpenHoursWeekdays: "11.30-14",
    lunchOpenHoursWeekends: "",
    lunchPrice: 135,
    lunchCouponPrice: 1090,
    adress: "Södra Ringgatan 30",
    zip: "441 33",
    city: "Alingsås",
    googleMapsUrl: "https://goo.gl/maps/gKdeeTFJPrgdpUyq7",
    feature: {
      catering: false,
    },
    cateringContact: [],
    foodboxesContacts: [{ "name": "Rasmus Packendorff", "phone": "0704081352" }, { "name": "Jonas Thunberg", "phone": "0708933006" }]
  },
];

export default Contacts;
